<template>
  <div class="home_box">
    <div class="h_row1">
      <div class="h_row1_1">
        <div class="h_row1_2">
          <el-avatar :size="40" :src="user_icon"></el-avatar>
          <span>按时</span>
        </div>
        <div class="h_row1_3">
          <img src="../../assets/img/exit_img.png" alt="" />
        </div>
      </div>
      <div class="h_row1_4">
        <div class="h_row1_5">
          我的面试
          <span></span>
        </div>
      </div>
    </div>
    <div class="h_row2_box" v-for="(item,index) in tableData" :key="index">
      <div class="h_row2_item">
        <div class="row2_top1">
          <div><i class="el-icon-time"></i></div>
          <div>
            <template v-if="item.start_time&&item.end_time">
              <span>{{ time_format(item.start_time) }}</span>
              ~
              <span>{{ time_format(item.end_time) }}</span>
            </template>
            <template v-else>-</template>
          </div>
          <div>
            <!-- <el-button plain size="mini" class="top_type1">已签到</el-button> -->
          </div>
        </div>
        <div class="row2_top2">
          <!-- status:1，//1修改时间待确认;5未签到;10已签到; 15已完成;20已取消 -->
          <!-- "method": 1, 线上  2 线下吗 -->
          <div class="row2_top2_txt" v-if="item.method==2">
            <span class="o_info_top" v-if="item.status==5" 
            @click="$router.push({name:'sign_in',query:{
              order_id,candidate_userid,
              start_time:item.start_time,
              end_time:item.end_time,
            }})">
            签到
            </span>
            <span v-else>签到</span>
          </div>
          <div class="row2_top2_txt" v-if="item.method==1">
            <span class="o_info_top" @click="open_link">
            面试链接
            </span>
          </div>
          <el-divider direction="vertical"></el-divider>
          <!-- <div class="row2_top2_txt">
            <div class="row2_top2_txt o_info_top" @click="is_attend_dlg = true">
              是否参加⾯试
            </div>
          </div>
          <el-divider direction="vertical"></el-divider> -->
          <div class="row2_top2_txt">
            <span class="o_info_top" v-if="item.status==5" @click="confirm_set_time_dlg = true">修改时间</span>
            <span v-else>修改时间</span>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="row2_top2_txt">
            <span class="o_info_top" v-if="[1,5].includes(item.status)" @click="c_interview_dlg = true">取消面试</span>
            <span v-else>取消面试</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 取消面试 弹框 -->
    <el-dialog
      title=" "
      :visible.sync="c_interview_dlg"
      width="80%"
      top="60%"
      center
    >
      <div class="dlg_body">
        <div class="dlg_title1">确认取消面试？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          @click="c_interview_dlg_confirm"
          class="confirm_btn"
          >确定</el-button
        >
        <el-button
          size="small"
          @click="c_interview_dlg = false"
          class="cancel_btn"
          >取消</el-button
        >
      </span>
    </el-dialog>

    <!-- 确认修改时间 弹框 -->
    <el-dialog
      title=" "
      :visible.sync="confirm_set_time_dlg"
      width="80%"
      top="60%"
      center
    >
      <div class="dlg_body">
        <div class="dlg_title1">确认修改时间？</div>
        <div class="dlg_title2">若修改时间，则可能被面试官拒绝</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          @click="set_time_dlg = true"
          class="confirm_btn"
          >确定</el-button
        >
        <el-button
          size="small"
          @click="confirm_set_time_dlg = false"
          class="cancel_btn"
          >取消</el-button
        >
      </span>
    </el-dialog>

    <!-- 确认修改时间 弹框 -->
    <el-dialog
      title="选择时间"
      :visible.sync="set_time_dlg"
      width="80%"
      top="60%"
      center
    >
      <div class="dlg_main">
        <div>
          <div class="time_box">
              <el-date-picker
                  @change="time_change"
                  v-model="start"
                  type="datetime"
                  value-format="timestamp"
                  placeholder="请选择时间">
              </el-date-picker>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          @click="set_time_dlg_confirm"
          class="confirm_btn"
          >确定</el-button
        >
        <el-button size="small" @click="set_time_dlg = false" class="cancel_btn"
          >取消</el-button
        >
      </span>
    </el-dialog>

    <!-- 是否参加⾯试 弹框 -->
    <el-dialog
      title="是否参加⾯试"
      :visible.sync="is_attend_dlg"
      width="80%"
      top="60%"
      center
    >
      <div class="dlg_main">
        <div>
          <div class="time_box">
            <el-radio-group v-model="is_attend.is_attend" @change="is_attend_change">
              <el-radio :label="1">参加</el-radio>
              <el-radio :label="0">拒绝</el-radio>
            </el-radio-group>
          </div>
          <div class="time_box" v-show="is_attend.is_attend==1">
              <el-date-picker
                  v-model="is_attend.start_time"
                  type="datetime"
                  value-format="timestamp"
                  placeholder="请选择时间">
              </el-date-picker>
          </div>
          <div class="time_box" v-show="is_attend.is_attend==0">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入拒绝理由"
              v-model="is_attend.refuse_reason">
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          @click="is_attend_dlg_confirm"
          class="confirm_btn"
          >确定</el-button
        >
        <el-button size="small" @click="is_attend_dlg = false" class="cancel_btn"
          >取消</el-button
        >
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {getWorkOrder,modifyInterviewTime,cancel,isAttend,interviewUrl} from '@/api/api';
export default{
    data() {
        return {
            user_icon:require("../../assets/img/u_icon.png"),
            c_interview_dlg:false,
            confirm_set_time_dlg:false,
            set_time_dlg:false,

            order_id:"",
            candidate_userid:"",
            tableData:[],
            start:"",
            is_attend_dlg:false,
            is_attend:{
              is_attend:1,
              start_time:"",
              refuse_reason:"",
            }
        }
    },
    beforeMount() {
        if (window.location.href.indexOf("?") !== -1) {
            // order_id   candidate_userid
            // 一个是订单id一个是候选人id
            // https://candidate.hibuddy.com.cn/?order_id=6513a4270f4b201cf63174cc&candidate_userid=651239424cfbbc937931e27e
            const url = new URL(window.location.href);
            const order_id = url.searchParams.get("order_id");
            const candidate_userid = url.searchParams.get("candidate_userid");
            this.order_id = order_id;
            this.candidate_userid = candidate_userid;
            sessionStorage.setItem("order_id",order_id);
            sessionStorage.setItem("candidate_userid",candidate_userid);
        }
    },
    watch:{
      set_time_dlg(){
        if(this.set_time_dlg){
          this.start="";
          this.set_initial_time();
        }
      },
      is_attend_dlg(){
        if(this.is_attend_dlg){
          this.is_attend.is_attend=1;
          this.is_attend.start_time="";
          this.is_attend.refuse_reason="";
          this.set_initial_time();
        }
      }
    },
    mounted() {
      this.get_list();
    },
    methods: {
        async get_list(){
            const res = await getWorkOrder({
                order_id:this.order_id,
                candidate_userid:this.candidate_userid,
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.tableData = res.data.data.list;
            // console.log(res.data.data);
        },
        time_change(v){
          v
          // console.log(v);
        },
        async set_time_dlg_confirm(){
          if(!this.start){
            return this.msg("error","请选择时间");
          }
          const res = await modifyInterviewTime({
              order_id:this.order_id,
              candidate_userid:this.candidate_userid,
              start_time:this.start
            });
            if(res.data.code!=200){
              return this.msg("error",res.data.msg);
            }
            this.msg("success","修改成功");
            this.set_time_dlg = false;
            this.confirm_set_time_dlg = false;
            this.get_list();
        },
        // 设置默认时间 开始的时间默认是早上9点，结束时间默认是晚上6点
        set_initial_time(){
            // 创建一个新的 Date 对象，表示当前时间
            let now = new Date();
            let year = now.getFullYear();    // 年份
            let month = now.getMonth() + 1;  // 月份（注意：月份从0开始，所以需要加1）
            let day = now.getDate();         // 日
            let c_time = `${year}-${month}-${day} 09:00:00`;
            // 时间戳
            let c_time_stamp = new Date(c_time).getTime();
            this.start = c_time_stamp;
            this.is_attend.start_time = c_time_stamp;
        },
        async c_interview_dlg_confirm(){
          const res = await cancel({
            order_id:this.order_id,
            candidate_userid:this.candidate_userid,
          });
          if(res.data.code!=200){
            return this.msg("error",res.data.msg);
          }
          this.set_time_dlg=false;
          this.get_list();
        },
        async is_attend_dlg_confirm(){
          if(this.is_attend.is_attend==1){
            if(!this.is_attend.start_time){
              return this.msg("error","请选择时间");
            }
          }else{
            if(!this.is_attend.refuse_reason){
              return this.msg("error","请输入拒绝理由");
            }
          }
          const res = await isAttend({
            order_id:this.order_id,
            candidate_userid:this.candidate_userid,
            is_attend:this.is_attend.is_attend,
            start_time:this.is_attend.start_time,
            refuse_reason:this.is_attend.refuse_reason,
          });
          if(res.data.code!=200){
            return this.msg("error",res.data.msg);
          }
          this.msg("success","操作成功");
          this.is_attend_dlg = false;
          this.get_list();
        },
        is_attend_change(v){
          if(v==1){
            this.is_attend.refuse_reason="";
          }else{
            this.is_attend.start_time="";
          }
        },
        async open_link(){
          const res = await interviewUrl({
            order_id:this.order_id,
            candidate_userid:this.candidate_userid,
          });
          if(res.data.code!=200){
            return this.msg("error",res.data.msg);
          }
          // console.log(res.data.data.join_url);
          if(res.data.data.join_url){
            window.open(res.data.data.join_url, '_blank');
          }
        
        }

    }
}
</script>
    
<style scoped>
.home_box {
  min-height: 100vh;
  height: 100%;
  background: #f4f5fa;
}
.h_row1 {
  background: linear-gradient(313deg, #18aef3 -66.5%, #0a76e2 83.19%);
  box-sizing: border-box;
  padding: 1rem 1rem 0.2rem;
  color: #fff;
}
.h_row1_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.h_row1_2 {
  display: flex;
  align-items: center;
}
.h_row1_2 span {
  margin-left: 0.75rem;
}
.h_row1_3 img {
  width: 0.9rem;
}
.h_row1_4 {
  margin-top: 1.4rem;
}
.h_row1_5 {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding-bottom: 7px;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.8);
}
.h_row1_5 span {
  display: block;
  width: 45px;
  height: 2px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  background: #fff;
}
.h_row2_box {
  box-sizing: border-box;
  padding: 1rem;
}
.h_row2_item {
  border-radius: 0.375rem;
  background: #fff;
  box-shadow: 0px 6px 26px 0px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  padding: 1.2rem;
}
.row2_top1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;
}
.top_type1,
.top_type1:hover,
.top_type1:active,
.top_type1:focus,
.top_type1:valid {
  background: #e5e5e5;
  border-color: #e5e5e5;
  color: #a9a9a9;
  font-size: 0.75rem;
}
.top_type2,
.top_type2:hover,
.top_type2:active,
.top_type2:focus,
.top_type2:valid {
  background: #0bc97d;
  border-color: #0bc97d;
  color: #fff;
  font-size: 0.75rem;
}
.row2_top2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a9a9a9;
}
.row2_top2_txt {
  flex: 1;
  text-align: center;
  /* border-right: 1px solid #A9A9A9; */
}
/* .row2_top2>div:last-child{
    border:none;
} */
.dlg_title1 {
  color: #333;
  font-size: 0.875rem;
  font-weight: 400;
}
.dlg_title2 {
  color: #a9a9a9;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}
.dlg_body > div {
  line-height: initial;
}
.dlg_main {
  text-align: center;
}
.time_box {
  margin-bottom: 1rem;
}
.time_box:last-child {
  margin-bottom: 0;
}
</style>
